package com.deange.nastychristmas.ui.theme

import androidx.compose.ui.graphics.Color

val md_theme_light_primary = Color(0xFF296B2A)
val md_theme_light_onPrimary = Color(0xFFFFFFFF)
val md_theme_light_primaryContainer = Color(0xFFACF4A2)
val md_theme_light_onPrimaryContainer = Color(0xFF002203)
val md_theme_light_secondary = Color(0xFF53634F)
val md_theme_light_onSecondary = Color(0xFFFFFFFF)
val md_theme_light_secondaryContainer = Color(0xFFD6E8CE)
val md_theme_light_onSecondaryContainer = Color(0xFF111F0F)
val md_theme_light_tertiary = Color(0xFFBB1627)
val md_theme_light_onTertiary = Color(0xFFFFFFFF)
val md_theme_light_tertiaryContainer = Color(0xFFFFDAD7)
val md_theme_light_onTertiaryContainer = Color(0xFF410005)
val md_theme_light_error = Color(0xFFBA1A1A)
val md_theme_light_errorContainer = Color(0xFFFFDAD6)
val md_theme_light_onError = Color(0xFFFFFFFF)
val md_theme_light_onErrorContainer = Color(0xFF410002)
val md_theme_light_background = Color(0xFFFCFDF6)
val md_theme_light_onBackground = Color(0xFF1A1C19)
val md_theme_light_surface = Color(0xFFFCFDF6)
val md_theme_light_onSurface = Color(0xFF1A1C19)
val md_theme_light_surfaceVariant = Color(0xFFDEE5D8)
val md_theme_light_onSurfaceVariant = Color(0xFF424940)
val md_theme_light_outline = Color(0xFF73796F)
val md_theme_light_inverseOnSurface = Color(0xFFF1F1EB)
val md_theme_light_inverseSurface = Color(0xFF2F312D)
val md_theme_light_inversePrimary = Color(0xFF91D888)
val md_theme_light_surfaceTint = Color(0xFF296B2A)

val md_theme_dark_primary = Color(0xFF91D888)
val md_theme_dark_onPrimary = Color(0xFF003908)
val md_theme_dark_primaryContainer = Color(0xFF0A5314)
val md_theme_dark_onPrimaryContainer = Color(0xFFACF4A2)
val md_theme_dark_secondary = Color(0xFFBACCB3)
val md_theme_dark_onSecondary = Color(0xFF253423)
val md_theme_dark_secondaryContainer = Color(0xFF3B4B38)
val md_theme_dark_onSecondaryContainer = Color(0xFFD6E8CE)
val md_theme_dark_tertiary = Color(0xFFFFB3AF)
val md_theme_dark_onTertiary = Color(0xFF68000E)
val md_theme_dark_tertiaryContainer = Color(0xFF930018)
val md_theme_dark_onTertiaryContainer = Color(0xFFFFDAD7)
val md_theme_dark_error = Color(0xFFFFB4AB)
val md_theme_dark_errorContainer = Color(0xFF93000A)
val md_theme_dark_onError = Color(0xFF690005)
val md_theme_dark_onErrorContainer = Color(0xFFFFDAD6)
val md_theme_dark_background = Color(0xFF1A1C19)
val md_theme_dark_onBackground = Color(0xFFE2E3DD)
val md_theme_dark_surface = Color(0xFF1A1C19)
val md_theme_dark_onSurface = Color(0xFFE2E3DD)
val md_theme_dark_surfaceVariant = Color(0xFF424940)
val md_theme_dark_onSurfaceVariant = Color(0xFFC2C8BD)
val md_theme_dark_outline = Color(0xFF8C9388)
val md_theme_dark_inverseOnSurface = Color(0xFF1A1C19)
val md_theme_dark_inverseSurface = Color(0xFFE2E3DD)
val md_theme_dark_inversePrimary = Color(0xFF296B2A)
val md_theme_dark_surfaceTint = Color(0xFF91D888)


val seed = Color(0xFF266627)
