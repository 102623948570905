package com.deange.nastychristmas.firebase

import kotlin.String

internal object BuildKonfig {
  public val FIREBASE_AUTH_DOMAIN: String = "nasty-christmas.firebaseapp.com"

  public val FIREBASE_API_KEY: String = "AIzaSyAdj0GsHnh0brBQd5kEpUzh5uoVVmEo28w"

  public val FIREBASE_PROJECT_ID: String = "nasty-christmas"

  public val FIREBASE_APP_ID: String = "1:960197137570:web:f65ab529012464a677b3fb"
}
